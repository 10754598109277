import UserStats from './UserStats';
import SectionStats from './SectionStats';
import AnswerCounts from './AnswerCounts';
import AnswerRevisionCollection from '@/models/answer/AnswerRevisionCollection';
import { newDate } from '@/utils/date';
import { getAvgOfValues, getPctOf } from '@/utils/math';

export default class HomeworkStats {
	constructor(homework = null, users = [], answerRevisions = []) {
		this.homework = homework;
		this.sections = homework?.getSections()?.getAllCompleteableSections() ?? [];
		this.exercises = this.sections.filter(section => section.isExercise());
		this.users = users ?? [];
		this.userStats = {};
		this.sectionStats = {};
		this.totalStats = {
			answerCounts: new AnswerCounts(),
		};
		this.setAnswerRevisions(answerRevisions);
	}

	setAnswerRevisions(answerRevisions) {
		this.answerRevisions = new AnswerRevisionCollection();
		answerRevisions.forEach(answerRevision => {
			const index = this.getAnswerRevisionIndex(answerRevision);
			if (index === -1) {
				this.answerRevisions.push(answerRevision);
			}
		});
		this.processUsers();
		this.processSections();
		this.progressTotalStats();
	}

	getAnswerRevisionIndex(answerRevision) {
		return this.answerRevisions.findIndex(aw => {
			return (
				aw.id === answerRevision.id ||
				(aw.user_id === answerRevision.user_id &&
					aw.section_relation_id === answerRevision.section_relation_id)
			);
		});
	}

	addAnswerRevision(answerRevision) {
		const index = this.getAnswerRevisionIndex(answerRevision);
		if (index > -1) {
			this.replaceAnswerRevision(answerRevision, index);
		} else {
			this.pushAnswerRevision(answerRevision);
		}
		const user = this.users.find(user => user.id == answerRevision.user_id);
		if (user) {
			this.userStats = {
				...this.userStats,
				...{ [user.id]: this.processUserStats(user) },
			};
		}

		const section = this.sections.find(
			section => section.section_relation_id == answerRevision.section_relation_id,
		);
		if (section) {
			this.sectionStats = {
				...this.sectionStats,
				...{ [section.section_relation_id]: this.processSectionStats(section) },
			};
		}
	}

	replaceAnswerRevision(answerRevision, index) {
		const oldAnswerRevision = this.answerRevisions.get(index);
		this.totalStats.answerCounts.replace(
			new AnswerCounts(oldAnswerRevision.answer_counts),
			new AnswerCounts(answerRevision.answer_counts),
		);
		this.answerRevisions.all().splice(index, 1, answerRevision);
	}

	pushAnswerRevision(answerRevision) {
		this.answerRevisions.unshift(answerRevision);
		this.totalStats.answerCounts.increment(new AnswerCounts(answerRevision.answer_counts));
	}

	processUsers() {
		this.userStats = Object.fromEntries(
			this.users.map(user => {
				return [user.id, this.processUserStats(user)];
			}),
		);
	}

	processUserStats(user) {
		const sections =
			user.id in this.userStats
				? this.getUserSections(user)
				: this.sections.filter(section => {
						return !this.homework.studentIsExcludedFromSection(user, section);
				  });

		const data = sections.reduce(
			(data, section) => {
				const answerRevision = this.getUserAnwerRevisionOfSection(user, section);
				const taskCount = section.getExerciseTasks().length;
				const answerCount = answerRevision
					? this.getAnswerRevisionAnswerCount(answerRevision)
					: 0;
				const gradableTaskCount = section
					.getExerciseTasks()
					.filter(task => task.type.gradeable).length;
				const correctCount = section.isScreening()
					? answerRevision?.answer_counts?.screening_correct ?? 0
					: answerRevision?.answer_counts?.correct;
				const progress = this.getAnswerRevisionProgress(answerRevision, taskCount, answerCount);
				data.progress.push(progress);

				if (!progress) {
					if (section.isGradeable()) {
						data.points.push(0);
					}
					return data;
				}

				const points =
					section.isExercise() && section.isGradeable() ? answerRevision.points : null;

				if (points !== null) {
					data.points.push(points);
				}

				data.sectionStats[section.section_relation_id] = new SectionStats(section, {
					progress,
					points,
					correctCount,
					taskCount: gradableTaskCount,
				});

				const startTime = this.getAnswerRevisionStartTime(answerRevision);
				if (startTime) {
					data.startTime.push(startTime);
				}
				if (answerRevision.latest_answer_at) {
					data.endTime.push(newDate(answerRevision.latest_answer_at));
				}

				return data;
			},
			{
				progress: [],
				points: [],
				startTime: [],
				endTime: [],
				exercises: {},
				sectionStats: {},
			},
		);

		return new UserStats({
			sections,
			progress: getAvgOfValues(data.progress),
			points: data.points.length ? getAvgOfValues(data.points) : null,
			startTime: getEarliestDate(data.startTime),
			endTime: getLatestDate(data.endTime),
			sectionStats: data.sectionStats,
		});
	}

	getUserStats(user) {
		return user.id in this.userStats ? this.userStats[user.id] : new UserStats();
	}

	getUserSections(user) {
		return this.sections.filter(section => {
			return this.getUserStats(user).sectionRelationIds.includes(section.section_relation_id);
		});
	}

	getAnswerRevisionProgress(answerRevision, taskCount = 0, answerCount = 0) {
		// If there is no answerRevision progress is 0
		if (!answerRevision) {
			return 0;
		}

		// If the answerRevision is completed progress is 1 (100)
		if (answerRevision.is_completed) {
			return 100;
		}

		// If the answerRevision is not completed
		// the answer count relative to the task count determines progress
		return getPctOf(answerCount, taskCount);
	}

	getAnswerRevisionAnswerCount(answerRevision = null) {
		return new AnswerCounts(answerRevision?.answer_counts).total;
	}

	getAnswerRevisionStartTime(answerRevision) {
		if (answerRevision.first_answer_at) {
			return newDate(answerRevision.first_answer_at);
		}

		const createdAt = newDate(answerRevision.created_at);
		const homeworkDate = newDate(this.homework.created_at).unix();
		// If AnswerRevision is created within 1 second of homework it was most likely
		// created when the teacher reset all previous answers for homework.
		return Math.abs(createdAt.unix() - homeworkDate) > 1 ? createdAt : null;
	}

	getUserAnwerRevisionOfSection(user, section) {
		return this.answerRevisions.find(
			answerRevision =>
				answerRevision.user_id == user.id &&
				answerRevision.section_relation_id == section.section_relation_id,
		);
	}

	getTotalProgress() {
		const progresses = this.users.map(user => this.getUserStats(user).progress);
		return getAvgOfValues(progresses);
	}

	progressTotalStats() {
		this.answerRevisions.forEach(answerRevision => {
			this.totalStats.answerCounts.increment(new AnswerCounts(answerRevision.answer_counts));
		});
	}

	processSections() {
		this.sectionStats = Object.fromEntries(
			this.sections.map(section => {
				return [section.section_relation_id, this.processSectionStats(section)];
			}),
		);
	}

	processSectionStats(section) {
		const values = Object.values(this.userStats).reduce(
			(values, stat) => {
				if (!stat.sectionRelationIds.includes(section.section_relation_id)) {
					return values;
				}
				const userSectionStat = stat.getSectionStats(section);
				if (userSectionStat) {
					values.progress.push(userSectionStat.progress);
					if (userSectionStat.points !== null) {
						values.points.push(userSectionStat.points);
					}
				} else {
					values.progress.push(0);
					if (section.isGradeable()) {
						values.points.push(0);
					}
				}
				return values;
			},
			{ progress: [], points: [] },
		);

		return new SectionStats(section, {
			progress: getAvgOfValues(values.progress),
			points: getAvgOfValues(values.points),
		});
	}

	getSectionStats(section) {
		return section.section_relation_id in this.sectionStats
			? this.sectionStats[section.section_relation_id]
			: new SectionStats(section);
	}

	getWorstExercises(count = 0) {
		const stats = [...this.exercises]
			.filter(section => section.isGradeable())
			.map(section => {
				return {
					section,
					stats: this.getSectionStats(section),
				};
			})
			.sort((a, b) => {
				if (a.stats?.points === null) {
					return 1;
				}
				if (a.stats?.points === b.stats?.points) {
					return 0;
				}
				return a.stats?.points > b.stats?.points ? 1 : -1;
			});

		return count ? stats.slice(0, count) : stats;
	}

	getUserWorstExercises(count = 0, user) {
		const userStats = this.getUserStats(user);
		const stats = [...this.exercises]
			.filter(section => {
				if (!userStats.sectionRelationIds.includes(section.section_relation_id)) {
					return false;
				}
				return section.isGradeable();
			})
			.map(section => {
				return {
					section,
					stats: userStats.getSectionStats(section),
				};
			})
			.sort((a, b) => {
				if (a.stats?.points === null) {
					return 1;
				}
				if (a.stats?.points === b.stats?.points) {
					return 0;
				}
				return a.stats?.points > b.stats?.points ? 1 : -1;
			});

		return count ? stats.slice(0, count) : stats;
	}

	getLiveCompletedExercises(count = 0) {
		const results = [];
		for (let i = 0; i < this.answerRevisions.length; i++) {
			const answerRevision = this.answerRevisions.get(i);
			if (
				!answerRevision?.is_live ||
				!answerRevision?.is_completed ||
				!answerRevision?.latest_answer_at
			) {
				continue;
			}

			const section = this.exercises.find(
				section => section.section_relation_id === answerRevision.section_relation_id,
			);
			if (!section) {
				continue;
			}
			const user = this.users.find(user => user.id === answerRevision.user_id);
			if (!user) {
				continue;
			}

			results.push({
				section,
				user,
				date: answerRevision.latest_answer_at,
				points: section.isGradeable() ? answerRevision.points : null,
			});

			if (count && results.length >= count) {
				return results;
			}
		}

		return results;
	}
}

function getEarliestDate(dates) {
	if (!dates?.length) {
		return null;
	}
	return dates.sort((date1, date2) => {
		return date1.unix() - date2.unix();
	})[0];
}
function getLatestDate(dates) {
	if (!dates?.length) {
		return null;
	}
	return dates.sort((date1, date2) => {
		return date2.unix() - date1.unix();
	})[0];
}
