import { requestExport } from '@/api/results';
import { resolveNotification } from '@/models/notification';

const state = () => ({
	awaitingExports: [],
});

const getters = {
	isAwatingExport(state) {
		return state.awaitingExports.length > 0;
	},
	isAwaitingExportForHomeworkOfType:
		state =>
		({ homeworkId, type }) => {
			const entry = state.awaitingExports.find(
				entry => entry.homeworkId === homeworkId && entry.type === type,
			);
			return !!entry;
		},
};

const actions = {
	requestExport({ commit, rootState }, { type, display, schoolClassId, homeworkId }) {
		return requestExport(rootState.user.schoolId, schoolClassId, homeworkId, type).then(response => {
			commit('addAwaitingExportForHomework', { homeworkId, type, display });
			return response;
		});
	},
	exportReady({ commit }, data) {
		const notification = resolveNotification(data);
		if (!notification) {
			return;
		}
		commit('resolveAwaitingExportForHomework', {
			homeworkId: notification.homeworkId,
			type: notification.exportType,
		});
	},
};

const mutations = {
	addAwaitingExportForHomework(state, { homeworkId, type }) {
		state.awaitingExports.push({ homeworkId, type });
	},
	resolveAwaitingExportForHomework(state, { homeworkId, type }) {
		const index = state.awaitingExports.findIndex(
			entry => entry.homeworkId === homeworkId && entry.type === type,
		);
		if (index >= 0) {
			state.awaitingExports.splice(index, 1);
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
