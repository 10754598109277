<template>
	<div class="modal" :class="{ 'is-active': active }">
		<div class="modal-background" :class="animateCss(['fadeIn'])" @click="hide"></div>
		<div class="modal-wrapper">
			<div
				class="modal-content is-flex is-flex-direction-column"
				:class="animateCss(['fadeInUp', 'faster', 'delay-1s'])"
			>
				<slot name="top"></slot>
				<div class="columns is-mobile">
					<div class="column modal-left is-hidden-mobile" :class="leftColumnClass">
						<slot name="img"></slot>
					</div>
					<div class="column modal-right has-background-white" :class="rightColumnClass">
						<div
							class="modal-body has-text-left"
							:class="{ 'narrow-body-padding': narrowBodyPadding }"
						>
							<p v-if="title" class="title is-3">
								<span class="mr-2">{{ title }}</span>
								<span
									class="is-inline-block"
									style="vertical-align: bottom"
								>
									<BaseIcon
										v-if="tooltip"
										v-tippy="{ placement: 'bottom' }"
										icon="help"
										color="grey-dark"
										active-color="blue"
										:size="3"
										:show-background="true"
										:content="tooltip"
									/>
								</span>
							</p>
							<slot name="body"></slot>
						</div>
						<div class="modal-footer" :class="footerColumnClass">
							<slot name="footer"></slot>
						</div>
					</div>
				</div>
			</div>
			<button v-if="dismissible" class="modal-close is-large" aria-label="close" @click="hide" />
		</div>
	</div>
</template>

<script>
import animateCss from '@/mixins/animateCss';
import BaseIcon from '@/components/base/BaseIcon.vue';

export default {
	name: 'SplitModal',
	components: { BaseIcon },
	mixins: [animateCss],
	props: {
		title: {
			type: String,
			default: '',
		},
		dismissible: {
			type: Boolean,
			default: true,
		},
		imageWidth: {
			type: Number,
			default: 6,
		},
		leftColumnClasses: {
			type: [Array, Object],
			required: false,
			default: () => [],
		},
		footerColumnClasses: {
			type: [Array, Object],
			required: false,
			default: () => [],
		},
		reduceTopMargin: {
			type: Boolean,
			default: false,
		},
		tooltip: {
			type: String,
			default: '',
		},
		narrowBodyPadding: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			active: false,
		};
	},
	computed: {
		leftColumnClass() {
			const leftColumnClass = this.leftColumnClasses;
			leftColumnClass.push(`is-${this.imageWidth}`);
			return leftColumnClass;
		},
		rightColumnClass() {
			const width = 12 - this.imageWidth;
			const rightColumnClasses = [
				'is-12-mobile',
				`is-${width}-tablet`,
				`is-${width}-desktop`,
				`is-${width}-widescreen`,
				`is-${width}-fullhd`,
			];
			if (this.reduceTopMargin) {
				rightColumnClasses.push('reduce-top-margin');
			}
			return rightColumnClasses;
		},
		footerColumnClass() {
			const footerColumnClass = this.footerColumnClasses;
			if (footerColumnClass.filter(c => c.startsWith('has-background-')).length === 0) {
				footerColumnClass.push('has-background-grey-lighterer');
			}
			return footerColumnClass;
		},
	},
	beforeDestroy() {
		this.stopListeningForKeystrokes();
	},
	methods: {
		show() {
			this.active = true;
			if (this.dismissible) {
				this.startListeningForKeystrokes();
			}
			document.body.classList.add('modal-open');
		},
		hide() {
			if (!this.dismissible) {
				return;
			}
			this.stopListeningForKeystrokes();
			this.$emit('hide');
			this.active = false;
			document.body.classList.remove('modal-open');
		},
		onBodyKeyDown(event) {
			const key = typeof event.key === 'string' ? event.key.toLowerCase() : '';
			if (key === 'escape') {
				this.hide();
			}
		},
		startListeningForKeystrokes() {
			document.addEventListener('keydown', this.onBodyKeyDown);
		},
		stopListeningForKeystrokes() {
			document.removeEventListener('keydown', this.onBodyKeyDown);
		},
	},
};
</script>

<style lang="scss" scoped>
.modal {
	.modal-content {
		.columns {
			// Note: Same as $modal-background-background-color
			// but without opacity.
			background-color: rgba(31, 35, 58, 1);
			margin: 0;
			max-height: inherit;
			min-height: inherit;

			.modal-left {
				padding: 0;
				overflow: hidden;

				&:not(.has-fixed-img) {
					img {
						height: 100%;
						width: 100%;
						object-fit: cover;
					}
				}
			}

			.modal-right {
				padding: 0;
				position: relative;
				display: flex;
				max-height: inherit;
				min-height: inherit;

				.modal-header {
					position: fixed;
					top: 0;
					width: inherit;
					height: 64px;
				}

				.modal-body {
					margin-top: 64px;
					margin-bottom: 80px;
					padding: 20px 10%;
					overflow: auto;
					width: 100%;
					&.narrow-body-padding {
						padding: 20px;
					}
				}

				&.reduce-top-margin {
					.modal-body {
						margin-top: 1.5rem;
					}
				}

				.modal-footer {
					border-radius: 0 0 30px 0;
				}

				.columns {
					background-color: white;
				}
			}
		}
	}
}
</style>
