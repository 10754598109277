import {
	createScreening,
	getScreeningAssignments,
	getUserScreenings,
	getClassScreenings,
	getColleagueScreenings,
	getScreeningHomeworkCounts,
	getScreeningCreateFlow,
	getSchoolScreeningAssignment,
	getSchoolScreeningAssignmentType,
} from '@/api/screening';

const state = {};

const getters = {};

const actions = {
	createScreening({ rootState }, { class_id, data }) {
		return createScreening(rootState.user.schoolId, class_id, data);
	},
	getScreeningCreateFlow({ rootState }, { id }) {
		return getScreeningCreateFlow(rootState.user.schoolId, id);
	},
	getScreeningAssignments({ rootState }) {
		return getScreeningAssignments(rootState.user.schoolId);
	},
	getUserScreenings({ rootState }, { archived, page }) {
		return getUserScreenings(rootState.user.schoolId, archived, page);
	},
	getClassScreenings({ rootState }, { class_id }) {
		return getClassScreenings(rootState.user.schoolId, class_id);
	},
	getColleagueScreenings({ rootState }, { page }) {
		return getColleagueScreenings(rootState.user.schoolId, page);
	},
	getScreeningHomeworkCounts({ rootState }) {
		return getScreeningHomeworkCounts(rootState.user.schoolId);
	},
	getSchoolScreeningAssignment({ rootState }, { id, homeworkId, showAll = false }) {
		return getSchoolScreeningAssignment(rootState.user.schoolId, id, homeworkId, showAll);
	},
	getSchoolScreeningAssignmentType({ rootState }, typeId) {
		return getSchoolScreeningAssignmentType(rootState.user.schoolId, typeId);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
