<template>
	<div class="modal" :class="{ 'is-active': active, 'allow-overflow': allowOverflow }">
		<div class="modal-background" :class="animateCss(['fadeIn'])" @click="hide"></div>
		<div class="modal-wrapper">
			<div
				class="modal-content"
				:class="animateCss(['fadeInUp', 'faster', 'delay-1s'])"
				:style="{ width: `${width}px` }"
			>
				<div class="modal-body has-text-left">
					<p v-if="title" class="title is-3">{{ title }}</p>
					<slot name="body"></slot>
				</div>
				<div
					v-if="hasFooter"
					class="modal-footer has-background-grey-lighterer has-border-bottom-radius-large"
				>
					<slot name="footer"></slot>
				</div>
			</div>
			<button v-if="dismissible" class="modal-close is-large" aria-label="close" @click="hide" />
		</div>
	</div>
</template>

<script>
import animateCss from '@/mixins/animateCss';

export default {
	name: 'BasicModal',
	mixins: [animateCss],
	props: {
		title: {
			type: String,
			default: '',
		},
		width: {
			type: Number,
			default: 600,
		},
		dismissible: {
			type: Boolean,
			default: true,
		},
		allowOverflow: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			active: false,
		};
	},
	computed: {
		hasFooter() {
			return !!this.$slots.footer;
		},
	},
	beforeDestroy() {
		this.stopListeningForKeystrokes();
	},
	methods: {
		show() {
			this.$emit('show');
			this.active = true;
			this.$emit('shown');
			if (this.dismissible) {
				this.startListeningForKeystrokes();
			}
			document.body.classList.add('modal-open');
		},
		hide() {
			if (!this.dismissible) {
				return;
			}
			this.stopListeningForKeystrokes();
			this.$emit('hide');
			this.active = false;
			this.$emit('hidden');
			document.body.classList.remove('modal-open');
		},
		onBodyKeyDown(event) {
			const key = typeof event.key === 'string' ? event.key.toLowerCase() : '';
			if (key === 'escape') {
				this.hide();
			}
		},
		startListeningForKeystrokes() {
			document.addEventListener('keydown', this.onBodyKeyDown);
		},
		stopListeningForKeystrokes() {
			document.removeEventListener('keydown', this.onBodyKeyDown);
		},
	},
};
</script>

<style lang="scss" scoped>
.modal {
	.modal-content {
		display: flex;

		@media (max-width: 769px) {
			width: 100%;
		}

		.modal-header {
			position: fixed;
			top: 0;
			width: 100%;
			height: 64px;
		}

		.modal-body {
			margin-top: 64px;
			margin-bottom: 80px;
			padding: 20px 10%;
			overflow: auto;
			width: 100%;
		}
	}

	&.allow-overflow {
		.modal-content,
		.modal-body {
			overflow: visible;
		}
	}
}
</style>
