import Model from '@/models/Model';
import SectionCollection from '@/models/section/SectionCollection';
import Task from '@/models/task/Task';
import { newTask } from '@/models/task/';

export default class Section extends Model {
	getIdentifier() {
		return this.section_relation_id;
	}

	getDefaultValues() {
		return {
			section_relation_id: null,
		};
	}

	processValues(values = {}) {
		if (values.sections && !(values.sections instanceof SectionCollection)) {
			values.sections = new SectionCollection(values.sections);
		}
		return values;
	}

	getTypeName() {
		if (this.type && typeof this.type === 'object') {
			return this.type.machine_name;
		}
		return this.type;
	}

	isType(type) {
		return this.getTypeName() == type;
	}

	isExercise() {
		return ['exercise', 'follow_up', 'exercise_section'].includes(this.getTypeName());
	}

	isQuestion() {
		return this.isType('question');
	}

	isMedia() {
		return ['book_chapter_video_introduction', 'media'].includes(this.getTypeName());
	}

	hasSections() {
		return this.sections !== undefined && this.sections?.length > 0;
	}

	getSections() {
		if (!this.hasSections()) {
			return new SectionCollection();
		}
		return this.sections instanceof SectionCollection
			? this.sections
			: new SectionCollection(this.sections);
	}

	isCompleteable() {
		return this.isExercise() || !this.hasSections();
	}

	hasTasks() {
		return this.tasks !== undefined && this.tasks?.length > 0;
	}

	getTasks() {
		if (!this.hasTasks()) {
			return [];
		}
		return this.tasks.map(task => {
			return task instanceof Task ? task : newTask(task);
		});
	}

	getExerciseTasks() {
		if (!this.isExercise() || !this.hasSections()) {
			return [];
		}

		return this.sections.reduce((tasks, questionSection) => {
			if (!questionSection.hasTasks()) {
				return tasks;
			}
			return tasks.concat(questionSection.getTasks());
		}, []);
	}

	isGradeable() {
		// If the section has tasks it is a question. Check tasks for gradeable ones.
		if (this.hasTasks()) {
			return this.getTasks().filter(task => task.isGradeable()).length > 0;
		}

		// If the section is an exercise and has loaded sub sections (questions)
		// we check all the tasks for gradeable ones.
		if (this.isExercise() && this.hasSections()) {
			const tasks = this.getExerciseTasks();
			if (tasks.length > 0) {
				return tasks.filter(task => task.isGradeable()).length > 0;
			}
		}

		return this.gradeable ?? false;
	}

	getPathParts() {
		return Array.isArray(this.path_parts) ? this.path_parts : [];
	}

	getPathPart(part) {
		return this.getPathParts().find(pathPart => pathPart.value === part) || null;
	}

	getPath() {
		if (!this.path || typeof this.path !== 'string') {
			return '';
		}
		return `${!this.path.startsWith('/') ? '/' : ''}${this.path}`;
	}

	getRoute(defaultValue = {}, homeworkId = undefined) {
		const parts = this.getPathParts();
		if (!parts.length) {
			return defaultValue;
		}

		switch (parts[0].value) {
			case 'bog': {
				let routeName = 'book';
				let params = { id: parts[0].id };
				let hash = '';
				if (parts.length > 1) {
					routeName = 'book_chapter';
					params = {
						book_id: parts[0].id,
						chapter_id: parts[1].id,
					};
				}
				if (parts.length > 2) {
					routeName = 'book_chapter_section';
					params.section_id = parts[2].id;
				}
				if (parts.length > 3) {
					hash = `#section-${parts[3].id}`;
				}

				return {
					name: routeName,
					params,
					query: homeworkId ? { homeworkId } : {},
					hash,
				};
			}
			case 'opgavesaet':
				return {
					name: 'assignment',
					params: {
						id: parts[0].id,
					},
					query: homeworkId ? { homeworkId } : {},
					hash: parts.length > 1 ? `#section-${parts[1].id}` : '',
				};

			case 'screeninger':
				return {
					name: 'screening',
					params: {
						id: parts[0].id,
					},
					query: homeworkId ? { homeworkId } : {},
					hash: parts.length > 1 ? `#section-${parts[1].id}` : '',
				};
		}

		return defaultValue;
	}

	isScreening() {
		const parts = this.getPathParts();
		if (!parts.length) {
			return false;
		}
		return parts[0].value === 'screeninger';
	}

	getDifficulty(defaultValue = 0) {
		const difficulty = this.difficulty ? this.difficulty : this.conf?.difficulty;
		return parseInt(difficulty) || defaultValue;
	}

	getIconUrl() {
		if (this.icon_url) {
			return this.icon_url;
		} else if (this.icon) {
			return this.icon;
		}
		return null;
	}

	getUserExerciseProgress() {
		const tasks = this.getExerciseTasks();
		if (!tasks?.length) {
			return 0;
		}
		const completedTasks = tasks.filter(task => {
			if (!task.hasAnswer()) {
				return false;
			}
			return !!task.getAnswer().is_completed;
		});
		return completedTasks.length ? (completedTasks.length / tasks.length) * 100 : 0;
	}
}
